<script>
export default {
  data() {
    return {
      buttonClass:
        'shadow m-1 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2'
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CHANGE_PASSWORD':
          this.$router.push({
            name: 'User.BasicInfo.ChangePassword'
          })
          break
        case 'UPDATE':
          this.$router.push({ name: 'User.BasicInfo.Edit' })
          break
        case 'EXIT':
          this.$router.push({ name: 'Home' })
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- CHANGE PASSWORD BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('CHANGE_PASSWORD')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขรหัสผ่าน'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="key"></b-icon>
          <span class="text-sm">เปลี่ยนรหัสผ่าน</span>
        </b-button>
      </div>

      <!-- UPDATE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขข้อมูล'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="pencil-square"></b-icon>
          <span class="text-sm">แก้ไข</span>
        </b-button>
      </div>

      <!-- EXIT BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('EXIT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="box-arrow-right"
          ></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
