<script>
import { mapState } from 'vuex'

import Card from '@/components/card/Card.vue'
import { datetimeMixins } from '@/mixins'

export default {
  mixins: [datetimeMixins],
  components: {
    AppCard: Card
  },
  data() {
    return {
      fields: [
        { label: 'ผู้ใช้งาน', key: 'userId', type: 'string' },
        { label: 'ชื่อผู้ใช้งาน', key: 'userName', type: 'string' },
        { label: 'แผนก', key: 'department', type: 'string' },
        { label: 'ตำแหน่งงาน', key: 'jobTitle', type: 'string' },
        { label: 'ระดับ', key: 'userLevel', type: 'number' },
        { label: 'สร้างเมื่อ', key: 'createdAt', type: 'datetime' },
        {
          label: 'เข้าสู่ระบบล่าสุดเมื่อ',
          key: 'lastLoggedOnAt',
          type: 'datetime'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    getUserValue(field) {
      const { key, type } = field
      if (this.user && this.user[key]) {
        if (type === 'datetime') {
          return this.mxConvertToDatetimeBuddhistFormat(this.user[key])
        }
        return this.user[key]
      }
      return '-'
    }
  }
}
</script>

<template>
  <div class="py-3">
    <app-card>
      <b-row>
        <b-col cols="12" class="mb-2">
          <span class="text-lg font-weight-bold">ข้อมูลพื้นฐาน</span>
        </b-col>
        <b-col
          v-for="field in fields"
          :key="field.key"
          cols="12"
          md="6"
          class="mt-3"
        >
          <span class="mr-2 font-weight-bold ">{{ field.label }} :</span>
          <span>{{ getUserValue(field) }}</span>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style scoped></style>
